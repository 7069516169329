<!--生产发货情况-->
<template>
    <div class="productionDelivery">
        <ul class="taskStatistics">
            <li>
                <h4>总任务：</h4>
                <!--                <p class="scrollNumbers scrollNumbers1" ></p>-->
                <p class="scrollNumbers scrollNumbers1">
                    <span id="totalTask"></span><i class="unit">个</i>
                </p>
            </li>
            <li style="min-width: 22%">
                <h4>总任务方量：</h4>
                <p class="scrollNumbers scrollNumbers2">
                    <span id="taskPartyQuantity"></span><i class="unit">方</i>
                </p>
            </li>
            <li style="min-width: 22%">
                <h4>总发货方量：</h4>
                <p class="scrollNumbers scrollNumbers2">
                    <span id="shipperQuantity"></span><i class="unit">方</i>
                </p>
            </li>
            <li style="min-width: 18%">
                <h4>完成方量：</h4>
                <p class="scrollNumbers scrollNumbers2">
                    <span id="complateQuantity"></span><i class="unit">方</i>
                </p>
            </li>
            <li style="min-width: 18%">
                <h4>总完成率：</h4>
                <p class="scrollNumbers scrollNumbers2">
                    <span id="completionRate"></span><i class="unit">%</i>
                </p>
            </li>
        </ul>
        <div class="wrapper-box">
            <div class="station-statis">
                <ul>
                    <template v-for="(item,index) in productionData">
                        <template v-for="(childItem,childIndex) in item.ProductTask">
                            <li :class="['stationClass'+(index+1)]" :key="index+''+childIndex">
                                <div class="station-name">
                                    {{ childItem.station_name }}
                                </div>
                                <div class="station-num">
                                    <h4>任务方量：</h4>
                                    <p class="scrollNumbers scrollNumbers5">
                                        <span :id="'taskPartyNum'+index+childIndex"></span><i class="unit">方</i>
                                    </p>
                                    <h4>发货方量：</h4>
                                    <p class="scrollNumbers scrollNumbers6">
                                        <span :id="'shipperNum'+index+childIndex"></span><i class="unit">方</i>
                                    </p>
                                    <div class="jdt">
                                        <div class="jdt-val">
                                            {{ childItem.rate }}%
                                        </div>
                                        <div class="jdt-bg" :style="{height:childItem.rate+'%'}"></div>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>
            <div class="chartsStyle1">
                <h2 class="chart-title">
                    <i></i>发货方量动态排序
                </h2>
                <div id="deliveryStatusChart"></div>
            </div>
        </div>
        <div class="table-data">
            <table v-if="carList.length>0">
                <tr>
                    <th rowspan="2">
                        <div class="send-car-statis">
                            <h4>总车次：</h4>
                            <p class="scrollNumbers scrollNumbers3">
                                <span id="departure"></span><i class="unit">车</i>
                            </p>
                        </div>
                    </th>
                    <template v-for="item in carList">
                        <th :colspan="item.Cars.length" :key="item.tenantId">
                            {{ item.tenantName }}
                        </th>
                    </template>
                </tr>
                <tr>
                    <template v-for="item in carList">
                        <template v-if="(item.Cars||[]).length>0">
                            <template v-for="car in item.Cars">
                                <th :key="car.station_id">
                                    {{ car.station_name }}
                                </th>
                            </template>
                        </template>
                        <template v-else>
                            <td :key="'null'+item.tenantId"></td>
                        </template>
                    </template>
                </tr>
                <tr class="cur-cars">
                    <th>当前保<br>有车辆</th>
                    <template v-for="(item,i) in carList">
                        <template v-if="(item.Cars||[]).length>0">
                            <template v-for="(car,j) in item.Cars">
                                <td :key="'carcount'+i+j">
                                    <span :id="'carcount'+i+j"></span>
                                </td>
                            </template>
                        </template>
                        <template v-else>
                            <td :key="i"></td>
                        </template>
                    </template>
                </tr>
                <tr class="run-cars">
                    <th>当前运<br>行车辆</th>
                    <template v-for="(item,i) in carList">
                        <template v-if="(item.Cars||[]).length>0">
                            <template v-for="(car,j) in item.Cars">
                                <td :key="'runcount'+i+j">
                                    <span :id="'runcount'+i+j"></span>
                                </td>
                            </template>
                        </template>
                        <template v-else>
                            <td :key="i"></td>
                        </template>
                    </template>
                </tr>
                <tr class="out-cars">
                    <th>外借车</th>
                    <template v-for="(item,i) in carList">
                        <template v-if="(item.Cars||[]).length>0">
                            <template v-for="(car,j) in item.Cars">
                                <td :key="'jiecount'+i+j">
                                    <span :id="'jiecount'+i+j"></span>
                                </td>
                            </template>
                        </template>
                        <template v-else>
                            <td :key="i"></td>
                        </template>
                    </template>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import largeScreenFun from './JS/index.js';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
    name: 'production-delivery',
    components: {},
    props: {},
    data() {
        return {
            // 当前选择的租户id
            tenantId: '',
            tenantList: [],
            time: '',
            barChart: null,
            statisData: {
                rcount: 0,
                quantity: 0,
                volume: 0,
                frcount: 0,
            },
            // 生产数据列表
            productionData: [],
            // 车辆列表
            carList: [],
        };
    },
    watch: {
        statisData(val, oldVal) {
            if (val.rcount !== oldVal.rcount) {
                largeScreenFun.electronicNumber('#totalTask', val.rcount || 0);
            }
            if (val.quantity !== oldVal.quantity) {
                largeScreenFun.electronicNumber('#taskPartyQuantity', val.quantity || 0);

            }
            if (val.volume !== oldVal.volume) {
                largeScreenFun.electronicNumber('#shipperQuantity', val.volume || 0);
            }
            if (val.frcount !== oldVal.frcount) {
                largeScreenFun.electronicNumber('#departure', val.frcount || 0);
            }

            if (val.fillvolume !== oldVal.fillvolume) {
                largeScreenFun.electronicNumber('#complateQuantity', val.fillvolume || 0);
            }

            if (val.quantity !== oldVal.quantity || val.volume !== oldVal.volume) {
                const completionRate = NP.times(NP.divide(this.statisData.fillvolume || 0, this.statisData.quantity || 0), 100).toFixed(2);
                largeScreenFun.electronicNumber('#completionRate', completionRate || 0);
            }
        },
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        async initPage() {
            this.time = this.$parent.time;
            this.stationId = this.$parent.stationId;
            this.tenantId = this.$parent.tenantId;
            this.tenantList = this.$parent.tenantList;
            this.barChart = this.$echarts.init(document.getElementById('deliveryStatusChart'));

            await this.loadPage();

        },
        // 加载数据
        async loadPage() {
            const shipmentStatistics = await largeScreenFun.getShipmentStatistics(this.time, this.stationId, this.tenantId);
            if (shipmentStatistics) {
                this.statisData = shipmentStatistics;
            }
            this.getCarList();
            await this.getProductionStationData();
            this.initloadShipmentStatistics();
            this.drawBar();
        },
        // 加载发货统计数据
        async initloadShipmentStatistics() {
            largeScreenFun.electronicNumber('#totalTask', this.statisData.rcount || 0);
            largeScreenFun.electronicNumber('#taskPartyQuantity', this.statisData.quantity || 0);
            largeScreenFun.electronicNumber('#shipperQuantity', this.statisData.volume || 0);
            const completionRate = NP.times(NP.divide(this.statisData.fillvolume || 0, this.statisData.quantity || 0), 100).toFixed(2);
            largeScreenFun.electronicNumber('#completionRate', completionRate || 0);
            largeScreenFun.electronicNumber('#complateQuantity', this.statisData.fillvolume || 0);
            setTimeout(() => {
                largeScreenFun.electronicNumber('#departure', this.statisData.frcount || 0);
            }, 600);
        },
        // 获取场站生产数据
        async getProductionStationData() {
            const data = await largeScreenFun.getProductionStation(this.time, this.tenantId);
            // 待滚动的数组 domId
            const taskPartyNums = [];
            const shipperNums = [];
            data.forEach((item, index) => {
                item.ProductTask.forEach((childItem, childIndex) => {
                    childItem.rate = NP.times(NP.divide(childItem.volume || 0, childItem.quantity || 0), 100).toFixed(2);
                    taskPartyNums.push({
                        domId: 'taskPartyNum' + index + childIndex,
                        val: childItem.quantity,
                    });
                    shipperNums.push({
                        domId: 'shipperNum' + index + childIndex,
                        val: childItem.volume,
                    });
                });
            });
            this.productionData = data;
            this.$nextTick(() => {
                taskPartyNums.forEach(item => {
                    largeScreenFun.electronicNumber('#' + item.domId, item.val);
                });
                shipperNums.forEach(item => {
                    largeScreenFun.electronicNumber('#' + item.domId, item.val);
                });
            });
        },

        // 获取车辆列表
        async getCarList() {
            const carList = await largeScreenFun.getCarStatis(this.time, this.tenantId);
            const carDomId = [];
            carList.forEach((carItem, i) => {
                this.tenantList.forEach(tenant => {
                    if (carItem.tenantId === tenant.tenantId) {
                        carItem.tenantName = tenant.tenantName;
                    }
                });
                carItem.Cars.forEach((car, j) => {
                    carDomId.push({
                        domId: 'carcount' + i + j, val: car.carcount,
                    }, {
                        domId: 'runcount' + i + j, val: car.runcount,
                    }, {
                        domId: 'jiecount' + i + j, val: car.jiecount,
                    });
                });
            });
            // 设置滚动id
            this.carList = carList;
            this.$nextTick(() => {
                carDomId.forEach(item => {
                    largeScreenFun.electronicNumber('#' + item.domId, item.val);
                });
            });
        },
        // 变更场站
        changeStation() {
            this.loadPage();
        },
        // 加载柱图-发货情况
        async drawBar() {
            const colors = [['#0BA360', '#3CBA92'], ['#EFAB54', '#FA8C47'], ['#FCEF20', '#FEBD29']];
            const list = [];
            this.productionData.forEach((item, index) => {
                item.ProductTask.forEach(childItem => {
                    list.push({
                        station_name: childItem.station_name,
                        volume: childItem.volume,
                        color: colors[index],
                    });
                });
            });
            list.sort((a, b) => {
                return a.volume - b.volume;
            });
            const seriesData = [];
            const axis = [];
            list.forEach(item => {
                axis.push(item.station_name);
                seriesData.push({
                    value: item.volume,
                    label: {
                        show: true,
                        position: 'right',
                        color: item.color[0],
                        fontSize: '18',
                        fontFamily: 'Technology-Regular, Technology',
                        valueAnimation: true,
                    },
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: item.color[0], // 0% 处的颜色
                            }, {
                                offset: 1, color: item.color[1], // 100% 处的颜色
                            }],
                            global: false, // 缺省为 false
                        },
                    },
                });
            });
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: false,
                        },
                    },
                },
                legend: {
                    show: false,
                    textStyle: {
                        color: 'rgba(255,255,255,0.6)',
                    },
                    right: 10,
                },
                grid: {
                    left: 70,
                    top: 32,
                    right: 50,
                    bottom: 10,
                },
                calculable: true,
                yAxis: [{
                    type: 'category',
                    data: axis,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.2)',
                            width: 0.5,
                            type: 'dashed',
                        },
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.6)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                }],
                xAxis: [
                    {
                        position: 'top',
                        name: '（方）',
                        nameLocation: 'start',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.6)',
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.2)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLabel: {
                            color: 'rgba(255,255,255,0.6)',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.2)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [{
                    name: '发货方量',
                    type: 'bar',
                    barMaxWidth: 16,
                    data: seriesData,
                    itemStyle: {},
                    label: {
                        show: true,
                        position: 'right',
                        // color: index === 0 ? '#26BFFA' : '#FEBA3F',
                        fontSize: '18',
                        fontFamily: 'Technology-Regular, Technology',
                        valueAnimation: true,
                    },

                }],
                // animationDuration: 0,
                animationDurationUpdate: 1000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear',
            };
            this.barChart.setOption(option);
        },

    },

};
</script>

<style scoped lang="stylus">
.productionDelivery
    display flex
    flex-direction column
    .taskStatistics
        padding 0.15rem
        background: linear-gradient(180deg, rgba(63, 116, 190, 0) 0%, rgba(63, 116, 190, 0.2) 100%);
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(44, 71, 110, 0), rgba(44, 71, 110, 1)) 1 1;
    .wrapper-box
        width 100%;
        height calc(100% - 3.3rem)
        display flex
        .station-statis
            width 7.39rem;
            height 100%;
            overflow-x hidden
            overflow-y auto
            ul
                padding-top 0.07rem;
                width 100%;
                height 100%;
                display flex
                flex-wrap wrap
                align-content flex-start
                li
                    margin-left 0.1rem
                    margin-bottom 0.1rem
                    width 2.32rem;
                    height 1.3rem
                    background: #0B2227;
                    box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.5);
                    border: 1px solid #2C696E;
                    display flex
                    .station-name
                        display flex
                        justify-content center
                        align-items center
                        padding 0 0.1rem;
                        line-height 0.2rem;
                        width 0.43rem
                        height 100%;
                        background: #14444D;
                        box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.5), 0 0rem 0.06rem 0 rgba(255, 255, 255, 0.3);
                        border: 1px solid #2C606D;
                        font-size 0.18rem
                        color  #48F3FF
                    .station-num
                        flex 1
                        display flex
                        flex-direction column
                        justify-content center
                        padding-left 0.2rem;
                        position relative
                        .scrollNumbers
                            margin-top 0.05rem
                            span
                                display inline-block
                                height 0.24rem!important
                                >>> .num
                                    margin auto 0.01rem
                                    div
                                        width 0.18rem
                                        text-align center
                                        font-size 0.22rem
                                        line-height: 0.24rem;
                                        /*margin: 0.04rem 0;*/
                                        &.decimalPoint
                                            background none !important
                                            width 0.03rem
                        h4
                            font-size 0.14rem;
                            color #fff;
                            margin-top 0.04rem
                            margin-bottom -0.025rem;
                        .unit
                            font-size 0.14rem
                        .jdt
                            position absolute
                            right 0.12rem
                            bottom 0.12rem
                            width: 0.1rem
                            height: 0.86rem;
                            background: #061518;
                            border: 1px solid #143840;
                            cursor pointer
                            z-index 9
                            &:hover .jdt-val
                                display flex
                            .jdt-val
                                position absolute
                                left: -0.31rem;
                                top: -0.38rem;
                                width 0.7rem
                                height 0.3rem
                                background: linear-gradient(133deg, #194C91 0%, #4078C1 100%);
                                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                                border: 1px solid #75ADE9;
                                border-radius 0.03rem
                                display none;
                                justify-content center
                                align-items center
                                &:after
                                    display block
                                    content ''
                                    width 0
                                    height 0;
                                    border-width 0.05rem
                                    border-style solid
                                    border-color #4078C1 transparent  transparent  transparent
                                    position absolute
                                    left 0
                                    right 0
                                    bottom -0.1rem;
                                    margin 0 auto;
                                &:before
                                    display block
                                    content ''
                                    width 0
                                    height 0;
                                    border-width 0.06rem
                                    border-style solid
                                    border-color #75ADE9 transparent  transparent  transparent
                                    position absolute
                                    left 0
                                    right 0
                                    bottom -0.12rem;
                                    margin 0 auto;
                            .jdt-bg
                                position absolute
                                left 0
                                bottom 0
                                width 100%;
                                max-height 100%;
                                background: linear-gradient(180deg, #76E0A8 0%, #13BD63 100%);
                                transition height 2s linear
                .stationClass1
                    background: #0B2227;
                    box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.5);
                    border: 1px solid #2C696E;
                    .station-name
                        background: #14444D;
                        box-shadow: 0rem 0.02rem 0.08rem 0rem rgba(0, 0, 0, 0.5), 0rem 0rem 0.06rem 0rem rgba(255, 255, 255, 0.3);
                        border: 1px solid #2C606D;
                        color #48F3FF
                    .station-num
                        .jdt
                            .jdt-val
                                background: linear-gradient(180deg, #76E0A8 0%, #13BD63 100%);
                                box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.5);
                                border: 1px solid #76E0A8;
                                &:after
                                    border-color #13BD63 transparent  transparent  transparent
                                &:before
                                    border-color #76E0A8 transparent  transparent  transparent
                            .jdt-bg
                                background: linear-gradient(180deg, #76E0A8 0%, #13BD63 100%);
                .stationClass2
                    background: #271C0B;
                    box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.5);
                    border: 1px solid #654E29;
                    .station-name
                        background: #4D3214;
                        box-shadow: 0rem 0.02rem 0.08rem 0rem rgba(0, 0, 0, 0.5), 0rem 0rem 0.06rem 0rem rgba(255, 255, 255, 0.3);
                        border: 1px solid #6D512C;
                        color: #FFAB48
                    .station-num
                        .jdt
                            .jdt-val
                                background: linear-gradient(180deg, #EFAB54 0%, #FA8C47 100%);
                                box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.5);
                                border: 1px solid #EFAB54;
                                &:after
                                    border-color #FA8C47 transparent  transparent  transparent
                                &:before
                                    border-color #EFAB54 transparent  transparent  transparent
                            .jdt-bg
                                background: linear-gradient(180deg, #EFAB54 0%, #FA8C47 100%);
                .stationClass3
                    background: #1A1803;
                    box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.5);
                    border: 1px solid #504C1E;
                    .station-name
                        background: #3B3C0C;
                        box-shadow: 0rem 0.02rem 0.08rem 0rem rgba(0, 0, 0, 0.5), 0rem 0rem 0.06rem 0rem rgba(255, 255, 255, 0.3);
                        border: 1px solid #5E5A1F;
                        color: #DAC90E;
                    .station-num
                        .jdt
                            .jdt-val
                                background: linear-gradient(180deg, #FCEF20 0%, #FEBD29 100%);
                                box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.5);
                                border: 1px solid #FCEF20;
                                &:after
                                    border-color #FEBD29 transparent  transparent  transparent
                                &:before
                                    border-color #FCEF20 transparent  transparent  transparent
                            .jdt-bg
                                background: linear-gradient(180deg, #FCEF20 0%, #FEBD29 100%);
        .chartsStyle1
            width calc(100% - 7.6rem)
            height 100%;
            .chart-title
                padding-top 0.08rem;
                font-size 0.18rem
                color #fff;
                i
                    margin-right 0.05rem
                    display inline-block
                    width: 0.04rem;
                    height: 0.16rem;
                    background: #FFFFFF;
            #deliveryStatusChart
                width 100%;
                height calc(100% - 0.35rem);
    .table-data
        width 100%;
        height 2.4rem
        table
            table-layout fixed
            width 100%
            border:none;
            border-collapse separate
            border-spacing 0.02rem
            tr
                & th:nth-child(1)
                    width 1.08rem
                th
                    font-size 0.12rem;
                    font-weight normal
                    color rgba(255,255,255,0.8)
                td
                    font-size 0.14rem;
                    color rgba(255,255,255,0.8)
                    font-family technology
                    span
                        display inline-block
                        height 0.3rem
                        >>> .num
                            margin 0 0.01rem
                            div
                                width 0.24rem
                                text-align center
                                font-size 0.26rem
                                line-height 0.28rem
                th,td
                    height 0.45rem
                    text-align center
                    border none
                    box-shadow: 0 0 0.06rem 0 #4A74AB inset;
                    font-size 0.16rem
                    line-height 0.19rem
            .cur-cars
                th,td
                    color #44D7B6
                    box-shadow: 0 0 0.06rem 0 #21BC9A inset;
                    span
                        display inline-block
                        height 0.3rem
                        >>> .num
                            div
                                box-sizing border-box
                                background: #000000;
                                border: 0.01rem solid #094337;
            .run-cars
                th,td
                    color #F7B500
                    box-shadow: 0rem 0rem 0.06rem 0rem #B09353 inset;
                    span
                        >>> .num
                            div
                                box-sizing border-box
                                background: #000000;
                                border: 0.01rem solid #4D3B0B;
            .out-cars
                th,td
                    color #32C5FF
                    box-shadow: 0px 0px 6px 0px #32C5FF inset;
                    span
                        >>> .num
                            div
                                box-sizing border-box
                                background: #000E14;
                                border: 0.01rem solid #4D3B0B;
    .send-car-statis
        display flex
        flex-direction column
        justify-content center
        align-items center
        font-size: 0.16rem;
        color: rgba(255,255,255,0.8);
        h4
            margin-bottom 0.05rem
</style>
